<template>
<b-card :title="$t('specification')">
<div class="row mb-3">
  <div class="col-12 bg-light py-3">
    <h6>{{ $t('group_write_off') }}</h6>
    <p>Склад списання</p>
    <GroupFormAdd :groups="groups" :filterNames="filterNames" :items="items" @fetch="fetchData"/>
  </div>
</div>
<!-- Table -->
<h5>Список товарів</h5>
<b-table
v-if="items"
show-empty
small
hover
:items="items"
:fields="fields"
:current-page="currentPage"
:per-page="0">
<template slot="top-row" slot-scope="{ fields }">
  <td v-for="field in fields" :key="field.key">
    <b-form-input v-if="field.typesort === 'text'" size="sm" :placeholder="field.label" @keyup.enter="searchByText(field.key, $event)"></b-form-input>
    <div v-if="field.key === 'group'" class="form-group" >
      <select class="form-control form-control-sm" @change="searchByGroup(field.key, $event)">
        <option value=""> -- </option>
        <optgroup v-for="group in groups" :key="group.id" :label="group.name">
          <option v-for="child in group.children" :key="child.id" :value="child.id">{{ child.name }}</option>
        </optgroup>
      </select>
    </div>
    <b-input-group v-if="field.key === 'product'">
      <list-select
        v-if="productOptions"
        :list="productOptions"
        option-value="id"
        option-text="name"
        :selected-item="productSelected"
        @select="onSelectProduct"
        class="form-control form-control-sm"></list-select>
    </b-input-group>
    <div v-if="field.key === 'characteristic'" class="form-group">
      <list-select
        v-if="characteristicsOptions"
        :list="characteristicsOptions"
        option-value="id"
        option-text="name"
        :selected-item="characteristicSelected"
        @select="onSelectCharacteristic"
        class="form-control form-control-sm"></list-select>
    </div>
    <div v-if="field.key === 'stages'" class="form-group">
      <list-select
      v-if="stagesOptions"
      :list="stagesOptions"
      option-value="id"
      option-text="name"
      :selected-item="stageSelected"
      @select="onSelectStage"
      class="form-control form-control-sm"></list-select>
    </div>
  </td>
</template>
<template v-slot:head(selected)>
  <div class="custom-control custom-checkbox">
    <input type="checkbox" class="custom-control-input" id="select-all" @click="selectAll" v-model="allSelected">
    <label class="custom-control-label" for="select-all"></label>
  </div>
</template>
<template v-slot:cell(selected)="row">
  <div class="custom-control custom-checkbox">
    <input
      type="checkbox"
      class="custom-control-input"
      :id="`checkbox-${row.item.id}`"
      :name="`checkbox-${row.item.id}`"
      :value="row.item.id"
      :checked="row.item.selected"
      @change="selectItem({ product: row.item.id }, 'product', $event)">
    <label class="custom-control-label" :for="`checkbox-${row.item.id}`"></label>
  </div>
</template>
<template v-slot:row-details="row">
<Info :specifications="row.item.specifications" @fetch="fetchData" />
<b-button v-if="row.item.characteristics.length === 0" block size="sm" variant="outline-secondary" @click="row.item.show = !row.item.show">
  <fa v-if="!row.item.show" icon="caret-down"/>
  <fa v-else icon="caret-up"/>
</b-button>
<FormAdded v-if="row.item.characteristics.length === 0 && row.item.show" :groups="groups" :filterNames="filterNames" :ids="{ productId: row.item.id }" type="product" @fetch="fetchData"/>
<!-- characteristics -->
  <span v-else>
    <div v-for="characteristic in row.item.characteristics" :key="characteristic.id" class="row bg-light p-1 m-3">
      <div class="col-3 fw-bold">
        <div class="custom-control custom-checkbox">
          <input
            type="checkbox"
            class="custom-control-input"
            :id="`characteristic-${characteristic.id}`"
            :value="characteristic.id"
            :checked="characteristic.selected"
            @change="selectItem({ product: row.item.id, characteristic: characteristic.id }, 'characteristic', $event)">
          <label class="custom-control-label" :for="`characteristic-${characteristic.id}`">Характеристика</label>
        </div>
      </div>
      <div class="col-9 fw-bold">{{ characteristic.name }}</div>
      <div class="col-12">
        <Info :specifications="characteristic.specifications" @fetch="fetchData"/>
        <b-button block size="sm" variant="outline-secondary" @click="characteristic.show = !characteristic.show">
          <fa v-if="!characteristic.show" icon="caret-down"/>
          <fa v-else icon="caret-up"/>
        </b-button>
        <FormAdded v-if="characteristic.show" :groups="groups" :filterNames="filterNames" :ids="{ productId: row.item.id, characteristicId: characteristic.id }" type="characteristic" @fetch="fetchData"/>
      </div>
      <div class="col-12">
        <div class="row mt-2">
          <div class="col-11 ml-5" v-for="stage in characteristic.stages" :key="stage.id">
            <div class="custom-control custom-checkbox">
              <input
                type="checkbox"
                class="custom-control-input"
                :id="`stage-${stage.id}`"
                :value="stage.id"
                :checked="stage.selected"
                @change="selectItem({ product: row.item.id, characteristic: characteristic.id, stage: stage.id}, 'stage', $event)">
              <label class="custom-control-label fw-bold" :for="`stage-${stage.id}`">Етап</label> <span class="fw-bold">{{ stage.name }}</span>
            </div>
            <Info :specifications="stage.specifications" @fetch="fetchData" />
            <b-button block size="sm" variant="outline-secondary" @click="stage.show = !stage.show">
              <fa v-if="!stage.show" icon="caret-down"/>
              <fa v-else icon="caret-up"/>
            </b-button>
            <FormAdded v-if="stage.show" :groups="groups" :filterNames="filterNames" :ids="{ productId: row.item.id, characteristicId: characteristic.id, stageId: stage.id }" type="stage" @fetch="fetchData"/>
          </div>
        </div>
      </div>
    </div>
  </span>
</template>
</b-table>
<!-- Pagination -->
    <b-pagination size="sm" :total-rows="totalItems" v-model="currentPage" :per-page="perPage"/>
</b-card>
</template>
<script>
import axios from 'axios'
import { ListSelect } from 'vue-search-select'
import GroupFormAdd from './common/groupForm'
import FormAdded from './common/form'
import Info from './common/info'
export default {
  middleware: 'role:admin',
  layout: 'default',
  metaInfo () {
    return { title: this.$t('specification') }
  },
  components: {
    ListSelect,
    GroupFormAdd,
    FormAdded,
    Info
  },
  data: () => ({
    items: [],
    fields: [
      {
        key: 'selected',
        label: '',
        sortable: false
      },
      {
        key: 'group',
        label: 'Група',
        typesort: 'select',
        class: 'fw-bold'
      },
      {
        key: 'sku',
        label: 'Код товару',
        typesort: 'text',
        class: 'fw-bold'
      },
      {
        key: 'product',
        label: 'Назва',
        typesort: 'select',
        class: 'fw-bold'
      },
      {
        key: 'characteristic',
        label: 'Характеристика'
      },
      {
        key: 'stages',
        label: 'Етап'
      }
    ],
    allSelected: false,
    groups: null,
    productOptions: null,
    characteristicsOptions: null,
    stagesOptions: null,
    filterNamesParams: {},
    productSelected: {
      id: '',
      name: ''
    },
    characteristicSelected: {
      id: '',
      name: ''
    },
    stageSelected: {
      id: '',
      name: ''
    },
    filterNames: null,
    totalRows: 1,
    currentPage: 1,
    perPage: 10,
    totalItems: 0,
    urlParams: [],
    showGroupAdd: false
  }),
  mounted () {
    this.fetchGroup()
    this.fetchProducts()
    this.fetchFilterNames()
    this.fetchData()
  },
  computed: {},
  methods: {
    selectAll (e) {
      const checked = e.target.checked
      if (checked) {
        if (!this.allSelected) {
          this.selectItems(true)
        }
      } else {
        this.selectItems(false)
      }
    },
    selectItem (data, type, e) {
      const checked = e.target.checked
      if (checked) {
        this.selectItemByIds(data, type, true)
      } else {
        this.selectItemByIds(data, type, false)
      }
    },
    selectItemByIds(data, type, status) {
      if (type === 'product') {
          const pr = this.items.find(p => p.id === data.product)
          if (typeof pr !== 'undefined') {
            pr.selected = status
          }
        } else if (type === 'characteristic') {
          const pr = this.items.find(p => p.id === data.product)
          if (typeof pr !== 'undefined') {
            if (pr.characteristics.length > 0) {
              const char = pr.characteristics.find(c => c.id === data.characteristic)
              if (typeof char !== 'undefined') {
                char.selected = status
              }
            }
          }
        } else if (type === 'stage') {
          const pr = this.items.find(p => p.id === data.product)
          if (typeof pr !== 'undefined') {
            if (pr.characteristics.length > 0) {
              const char = pr.characteristics.find(c => c.id === data.characteristic)
              if (typeof char !== 'undefined') {
                if (char.stages.length > 0) {
                  const stage = char.stages.find(s => s.id === data.stage)
                  if (typeof stage !== 'undefined') {
                    stage.selected = status
                  }
                }
              }
            }
          }
        }
    },
    selectItems(status) {
      this.items.forEach(el => {
        el.selected = status
        if (el.characteristics.length > 0) {
          el.characteristics.forEach(c => {
            c.selected = status
            if (c.stages.length > 0) {
              c.stages.forEach(s => {
                s.selected = status
              })
            }
          })
        }
      })
      this.allSelected = status
    },
    onSelectProduct (key, val) {},
    onSelectCharacteristic (key, val) {},
    searchByText (key, e) {
      this.urlParams[key] = e.target.value
      this.fetchData()
    },
    searchBySelect (key, e) {
      const value = e.target.value
      this.urlParams[key] = value
      this.fetchData()
    },
    onSelectProduct (val) {
      this.urlParams['product'] = val.id
      this.productSelected = val
      this.fetchData()
      this.filterNamesParams.product = val.id
      this.fetchProducts()
      // unselect items
      this.selectItems(false)
    },
    onSelectCharacteristic (val) {
      this.urlParams['characteristic'] = val.name === ' -- ' ? '' : val.name
      this.characteristicSelected = val
      this.fetchData()
      if (val.name !== ' -- ') {
        this.filterNamesParams.characteristic = val.name
      }
      this.fetchProducts()
    },
    onSelectStage (val) {
      this.urlParams['stage'] = val.name === ' -- ' ? '' : val.name
      this.stageSelected = val
      this.fetchData()
      if (val.name !== ' -- ') {
        this.filterNamesParams.stage = val.name
      }
      this.fetchProducts()
    },
    searchByGroup (key, e) {
      const value = e.target.value
      // clear params
      this.urlParams = {}
      // set params
      this.urlParams[key] = value
      // clear selected
      this.productSelected = {}
      this.characteristicSelected = {}
      // clear params
      this.filterNamesParams = {}
      // set param
      this.filterNamesParams.group = value
      // refresh request
      this.fetchProducts()
      this.fetchData()
    },
    async fetchData () {
      // unselect items
      this.selectItems(false)
      const params = this.buildQuery() !== '' ? '&' + this.buildQuery() : ''
      const apiUrl = process.env.VUE_APP_ENDPOINT_V1
      await axios.get(apiUrl + 'specification?page=' + this.currentPage + params).then(resp => {
        if (resp.data) {
          const data = resp.data
          this.items = data.data
          this.currentPage =  data.current_page
          this.perPage = data.per_page
          this.totalItems = data.total
        }
      })
    },
    async fetchProducts () {
      this.productOptions = null
      this.characteristicsOptions = null
      this.stagesOptions = null
      const apiUrl = process.env.VUE_APP_ENDPOINT_V1
      await axios.post(apiUrl + 'product/names', this.filterNamesParams).then(resp => {
        this.productOptions = resp.data.products
        this.characteristicsOptions = resp.data.characteristics
        this.stagesOptions = resp.data.stages
      })
    },
    async fetchFilterNames () {
      const apiUrl = process.env.VUE_APP_ENDPOINT_V1
      await axios.post(apiUrl + 'specification/filter').then(resp => {
        this.filterNames = resp.data
      })
    },
    async fetchGroup () {
      const apiUrl = process.env.VUE_APP_ENDPOINT_V1
      await axios.post(apiUrl + 'groups/list').then(resp => {
        this.groups = this.listToTree(resp.data.data)
      })
    },
    listToTree (list) {
      const map = {}
      let node
      const roots = []
      for (let i = 0; i < list.length; i += 1) {
        map[list[i].id] = i
        list[i].children = []
      }
      for (let i = 0; i < list.length; i += 1) {
        node = list[i]
        if (node.parent_id > 0) {
          // if you have dangling branches check that map[node.parentId] exists
          list[map[node.parent_id]].children.push(node)
        } else {
          roots.push(node)
        }
      }
      return roots
    },
    buildQuery () {
      return Object.entries(this.urlParams).map(pair => pair.map(encodeURIComponent).join('=')).join('&')
    }
  },
  watch: {
    currentPage: {
      handler: function () {
        this.fetchData()
      }
    }
  }
}
</script>
