<template>
<div v-if="specifications.length > 0">
<p>Склад списання</p>
<table class="table table-sm">
  <thead>
    <tr>
      <th>Тип списання</th>
      <th>Група</th>
      <th>Код товару</th>
      <th>Товар</th>
      <th>Характеристика</th>
      <th class="text-center">К-сть</th>
      <th class="text-center">Од.вим.</th>
      <th></th>
    </tr>
  </thead>
  <tbody>
    <tr v-for="item in specifications" :key="item.id">
      <td>
        <span v-if="item.typeOfWriteOff === 'static'">Статичний</span>
        <span v-else>Динамічний</span>
      </td>
      <td>{{ item.group }}</td>
      <td>{{ item.sku }}</td>
      <td>{{ item.product }}</td>
      <td>{{ item.characteristic }}</td>
      <td class="text-center">{{ item.quantity }}</td>
      <td class="text-center">{{ item.unit }}</td>
      <td>
        <button class="btn btn-sm text-danger" @click.prevent="removeItem(item.id)"><fa icon="times" /></button>
      </td>
    </tr>
  </tbody>
</table>
</div>
</template>
<script>
import axios from 'axios'
export default {
  props: {
    specifications: {
      type: Array,
      required: true
    }
  },
  methods: {
    async removeItem (id) {
      const apiUrl = process.env.VUE_APP_ENDPOINT_V1
      await axios.delete(apiUrl + 'specification/' + id).then(resp => {
        if (resp.data.status) {
          this.$emit('fetch')
        }
      })
    }
  }
}
</script>